import { useEffect, useContext } from "react";
import { Button } from "../../components/Shadcn/Button";
import { Badge } from "../../components/Shadcn/Badge";
import { GlobalStateContext } from "../../components/ChatWiget/ContextState";

function WelcomeContent() {

  const { setIsChatOpen } =
  useContext(GlobalStateContext);
  
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      const SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50

      let container
      let camera, scene, renderer
      let particles, particle, count = 0
      let mouseX = 0, mouseY = 0
      let windowHalfX = window.innerWidth / 2
      let windowHalfY = window.innerHeight / 2

      init()
      animate()

      function init() {
        container = document.createElement('div')
        container.style.position = 'absolute'
        container.style.top = '0'
        container.style.left = '0'
        container.style.width = '100%'
        container.style.height = '100%'
        container.style.pointerEvents = 'none'
        document.body.appendChild(container)

        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000)
        camera.position.z = 1000

        scene = new THREE.Scene()

        particles = new Array()

        const geometry = new THREE.BufferGeometry()
        const vertices = []
        const material = new THREE.PointsMaterial({
          color: 0x0077be,
          size: 3,
          blending: THREE.AdditiveBlending,
          transparent: true,
          sizeAttenuation: false
        })

        for (let ix = 0; ix < AMOUNTX; ix++) {
          for (let iy = 0; iy < AMOUNTY; iy++) {
            const x = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2)
            const y = 0
            const z = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2)
            vertices.push(x, y, z)
          }
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))

        particles = new THREE.Points(geometry, material)
        scene.add(particles)

        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
        renderer.setSize(window.innerWidth, window.innerHeight)
        container.appendChild(renderer.domElement)

        document.addEventListener('mousemove', onDocumentMouseMove, false)
        window.addEventListener('resize', onWindowResize, false)
      }

      function onWindowResize() {
        windowHalfX = window.innerWidth / 2
        windowHalfY = window.innerHeight / 2

        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()

        renderer.setSize(window.innerWidth, window.innerHeight)
      }

      function onDocumentMouseMove(event) {
        mouseX = event.clientX - windowHalfX
        mouseY = event.clientY - windowHalfY
      }

      function animate() {
        requestAnimationFrame(animate)
        render()
      }

      function render() {
        camera.position.x += (mouseX - camera.position.x) * .05
        camera.position.y += (-mouseY - camera.position.y) * .05
        camera.lookAt(scene.position)

        const positions = particles.geometry.attributes.position.array

        let i = 0
        for (let ix = 0; ix < AMOUNTX; ix++) {
          for (let iy = 0; iy < AMOUNTY; iy++) {
            positions[i + 1] = (Math.sin((ix + count) * 0.3) * 50) + (Math.sin((iy + count) * 0.5) * 50)
            i += 3
          }
        }

        particles.geometry.attributes.position.needsUpdate = true

        renderer.render(scene, camera)

        count += 0.1
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-blue-600 text-white">
      {/* Hero Section */}
      <header className="relative container mx-auto px-4 py-32 text-center overflow-hidden">
        <div className="relative z-10">
          <Badge className="mb-4 bg-blue-400 text-blue-900">New: Tia 0.2 with enhanced NLP</Badge>
          <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in-up">Meet Tia, Your AI-Powered Employee Assistant</h1>
          <p className="text-xl text-blue-100 mb-8 animate-fade-in-up animation-delay-200">Ask questions, boost productivity, and enhance employee service with Tia's advanced conversational AI.</p>
          <Button onClick={()=> {setIsChatOpen(true)}} size="lg" className="text-lg bg-white text-blue-900 hover:bg-blue-100 animate-fade-in-up animation-delay-400">Try now</Button>
        </div>
      </header>

      <style jsx global>{`
        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fade-in-up {
          animation: fade-in-up 0.6s ease-out forwards;
        }
        .animation-delay-200 {
          animation-delay: 0.2s;
        }
        .animation-delay-400 {
          animation-delay: 0.4s;
        }
      `}</style>
    </div>
  )
}

export default WelcomeContent;
