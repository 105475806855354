import { MicRippleIcon } from "../Icons";

export const MicRippleComponent = () => {
    return (
      <div className="flex items-center justify-center w-full h-[200px]">
        <div className="relative">
          {/* Outermost circle */}
          <div className="absolute inset-0 rounded-full bg-blue-100 animate-ping" style={{ padding: '40%' }} />
          
          {/* Middle circle */}
          <div className="absolute inset-0 rounded-full bg-blue-200 animate-pulse" style={{ padding: '30%' }} />
          
          {/* Inner circle */}
          <div className="absolute inset-0 rounded-full bg-blue-300 animate-pulse" style={{ padding: '20%' }} />
          
          {/* Center circle with microphone */}
          <div className="relative rounded-full bg-blue-500 p-6">
            <MicRippleIcon />
          </div>
        </div>
      </div>
    )
}