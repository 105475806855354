import React from "react";
import WelcomeContent from "./WelcomeContent";

function HomePage() {
  return (
    <div className="overflow-y-scroll hide-scrollbar h-screen">
        <WelcomeContent />
    </div>
  );
}

export default HomePage;
