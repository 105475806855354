import HomePage from "../pages/HomePage";
import ChatWidgetPage from "../pages/ChatWidgetPage";

const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/chat",
    component: <ChatWidgetPage/>,
  },
];

export default routes;
