import React, { useContext } from "react";
import { ChatIcon, ChatActiveIcon, MicActiveIcon, MicIcon } from "../Icons";
import { GlobalStateContext } from "../../ContextState";
import { Badge } from "../../../Shadcn/Badge"

const ChatFooter = () => {
  const { tab, setTab } = useContext(GlobalStateContext);
  return (
    <>
      <div className="flex items-center  justify-around shadow-top h-[54px]">
        <div
          className="flex w-[25%] items-center justify-center cursor-[pointer]"
          onClick={() => setTab("home")}
        >
          {tab === "home" ? <ChatActiveIcon /> : <ChatIcon />}
        </div>
        <div
          className="flex w-[25%] items-center justify-center cursor-[pointer]"
          onClick={() => setTab("noti")}
        >
          {tab === "noti" ? <MicActiveIcon /> : <MicIcon />}
        </div>
      </div>
    </>
  );
};

export default ChatFooter;
